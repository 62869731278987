<template>
  <div class="gl-source-of-funds-search">
    <div class="flex h-display-block mb-4 mr-3 h-mr-0">
      <GlCoinSelect class="mr-2 h-mr-0 h-mb-3" />
      <GlSearchBox
        v-model="search"
        button-text="Free Checkup"
        class="fullwidth gl-source-of-funds-search-input"
        dark-clear
        :disabled="!search"
        height="50px"
        :loading="calcLoading || addressDataLoading || txDataLoading"
        placeholder="Enter the wallet address"
        @search="searchData"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapMutations, mapActions, mapState } from 'vuex'
//Components
import GlCoinSelect from '@/components/gl-coin-select'
import GlSearchBox from '@/components/gl-search-box'

export default {
  components: {
    GlCoinSelect,
    GlSearchBox,
  },
  props: {
    msg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      addressDataLoading: false,
      txDataLoading: false,
      calcLoading: false,
      activeValue: '',
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList']),
  },
  watch: {
    search: {
      handler(val) {
        if (!val) {
          this.$emit('data-result', [])
          this.$router.replace({ query: null })
        }
      },
    },
  },
  mounted() {
    const { query } = this.$route
    if (query.type) {
      this.SET_COIN_TYPE(query.type)
      const coin = this.currencyList.find(curr => curr.key === query.type)
      this.SET_COIN_DATA(coin)
    }
    if (query.address) {
      this.search = query.address
      this.searchData(query.address)
      return
    }
    if (query.tx) {
      this.search = query.tx
      this.searchData(query.tx)
    }
  },

  methods: {
    ...mapMutations('analytics', ['SET_COIN_TYPE', 'SET_COIN_DATA']),
    ...mapActions('report', ['getStartAddressCheck']),
    async searchData(value, recalc = true) {
      if (this.activeValue !== value) {
        this.$emit('data-result', [])
      }
      const valid = new RegExp(this.coinData.addressRegex).test(value)

      if (!valid) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Search value is not valid',
          life: 3000,
        })
        return
      }

      await this.SET_COIN_TYPE(this.coinType)

      if (
        new RegExp(this.coinData.addressRegex).test(value) &&
        this.$can('use', this.coinData.family)
      ) {
        // BTC ADDRESS
        this.addressDataLoading = true
        this.getStartAddressCheck({
          address: value,
          coin: this.coinData.key,
          recalculate: recalc,
        })
          .then(({ success, data }) => {
            if (success) {
              data = data.map(item => {
                return {
                  ...item,
                  value,
                  addFakeLoad: this.activeValue !== value,
                }
              })

              this.activeValue = value
              this.$emit('data-result', data)
            }
          })
          .finally(() => {
            this.addressDataLoading = false
          })

        await this.$router
          .replace({
            query: { address: value, type: this.coinType },
          })
          .catch(err => err)

        return
      }

      this.$toast.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Search value is not valid',
        life: 3000,
      })
    },
  },
}
</script>
<style>
.gl-source-of-funds-search-input .gl-search-box__input-wrapper {
  box-shadow: 0px 0px 15px 0px rgba(14, 11, 61, 0.05);
}
@media (max-width: 879px) {
  .gl-source-of-funds-search {
    max-width: 100%;
  }
  .coin-select-base .vs__dropdown-toggle {
    width: 100% !important;
  }
}
</style>