<template>
  <div class="sof-check-wrapper">
    <div class="sof-check-cards-wrapper">
      <div
        v-if="!packagesResult.length && isResultSeted"
        class="sof-oops-wrapper"
      >
        <div class="sof-oops-data">
          <gl-icon
            class="mb-2"
            :height="20"
            name="search-fail"
            :width="20"
          />
          <div>
            <span class="bold-600">Oops!</span> We're sorry, but we currently
            don't have any information related to the provided address. Please
            check back in the future, as we are continuously updating our
            database to bring you the most accurate and up-to-date information
            possible.
          </div>
        </div>
      </div>

      <div
        v-else
        class="sof-box-wrapper"
      >
        <transition-group name="slide">
          <div
            v-if="packagesResult.length"
            key="sof-box-head-info"
            class="sof-box-head-info-wrapper"
          >
            <div class="sof-box-head-preview">
              Example
            </div>
            <div>
              <b>{{ packagesResult.length }}</b> Reports Found
            </div>
          </div>
          <CardBox
            v-for="(pack, i) in packagesResult"
            :key="pack.reportPartName"
            :cart-data="pack"
            :hover-all-package="hoverAllPackage"
            :hovered-package-ind="hoveredPackageInd"
            :order="i"
            @button-click="boxButtonClick"
            @update-hover-all-package="updateHoverAllPackage"
            @update-hovered-package-ind="updateHoveredPackageInd"
            @update-package-by-key="updatePackageByKey"
          />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapState, mapActions, mapMutations } from 'vuex'
import CardBox from '@/pages/source-of-funds/components/CardBox.vue'
import GlIcon from '@/components/gl-icon'

export default {
  name: 'SourceOfFunds',
  components: {
    GlIcon,
    CardBox,
  },
  props: {
    dataSearchResult: {
      type: Array,
      default: () => [],
    },
    isResultSeted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoverAllPackage: false,
      hoveredPackageInd: null,
      whatCheckModal: false,
      packages: [],
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData']),
    ...mapState('user', ['userData']),
    packagesResult() {
      return this.dataSearchResult.map(item => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          !item.hasOwnProperty('fakeLoadingPreview') &&
          item.addFakeLoad &&
          !item.purchased
        ) {
          return {
            ...item,
            fakeLoadingPreview: Math.floor(Math.random() * 3) + 1,
          }
        } else {
          return { ...item }
        }
      })
    },
  },
  methods: {
    ...mapActions('report', ['purchaseReports']),
    ...mapActions('user', ['getMe']),
    ...mapMutations({
      SET_REDIRECT_PAYMENT_MODAL: 'payment/SET_REDIRECT_PAYMENT_MODAL',
    }),
    updatePackageByKey(e) {
      this.$emit('update-package-by-key', e)
    },
    updateHoverAllPackage(e) {
      this.hoverAllPackage = e
    },
    updateHoveredPackageInd(e) {
      this.hoveredPackageInd = e
    },
    redirectToLoginModal() {
      this.$confirm.require({
        message: 'Would you like to proceed to login?',
        header: "Looks like you're not logged in",
        acceptClass: 'p-button bold-600',
        rejectClass: 'p-button-outlined bold-600',
        accept: async () => {
          this.$router.push({ name: 'login' })
        },
      })
    },
    async boxButtonClick(packageData) {
      await this.getMe()
      if (!Object.keys(this.userData).length) {
        this.redirectToLoginModal()
        return
      }

      if (packageData.purchased && packageData.value) {
        await this.$router.push({
          name: 'report',
          query: {
            type: this.coinType,
            address: packageData.value,
          },
        })
      } else {
        if (this.userData.balance < packageData.price) {
          this.SET_REDIRECT_PAYMENT_MODAL(true)
          return
        }

        this.$confirm.require({
          message: 'Are you sure you want to proceed?',
          header: 'Confirmation',
          acceptClass: 'p-button bold-600',
          rejectClass: 'p-button-outlined bold-600',
          accept: async () => {
            this.$emit('update-package-by-key', {
              ...packageData,
              loading: true,
            })

            await this.purchaseReports({
              address: packageData.value,
              coin: this.coinData.key,
              reportPartName: packageData.reportPartName,
            })
              .then(e => {
                this.$emit('update-search-data', { value: packageData.value, recalc: false })
                this.getMe()
                if (!e?.success) {
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Error Message',
                    detail: e?.data?.message,
                    life: 3000,
                  })
                  return
                }
              })
              .finally(() => {
                this.$emit('update-package-by-key', {
                  ...packageData,
                  loading: false,
                })
              })
          },
          reject: () => {
            this.$emit('update-package-by-key', {
              ...packageData,
              loading: false,
            })
          },
        })
      }
    },
  },
}
</script>

<style>
.sof-check-wrapper {
  display: flex;
  font-family: 'Montserrat', sans-serif;
}

.sof-box-wrapper {
  display: flex;
  flex-direction: column;
}

.sof-check-cards-wrapper {
  flex: 1;
}

.sof-reports-wrapper {
  flex: 1;
  margin-left: 0px;
  height: 100%;
}
.sof-oops-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sof-oops-data {
  width: 80%;
  text-align: center;
  max-width: 700px;
}

.sof-box-head-info-wrapper {
  display: flex;
  gap: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 12px;
}

.sof-box-head-preview {
  width: 200px;
}

@media (max-width: 1023px) {
  .sof-check-wrapper {
    display: block;
  }
}
</style>
