<template>
  <div class="sof-wrapper">
    <div class="sof">
      <div class="sof-head-wrapper">
        <div class="sof-head">
          <div class="bold-600 fs-36 t-fs-20 mb-4">
            Check your wallet address
          </div>
          <SourceSearchBox
            ref="SourceSearchBoxRef"
            @data-result="setDataSearchResult"
          />
        </div>
      </div>
      <div class="sof-main-content-wrapper">
        <div class="sof-main-content">
          <transition-group
            class="full-height"
            name="slide"
            tag="div"
          >
            <div
              v-if="!isResultSeted"
              key="reportHistory"
              class="flex column full-height"
            >
              <div class="bold-600 fs-24 t-fs-20 mb-1">
                Your recent reports
              </div>
              <div class="bold-500 fs-14 mb-3">
                Download your previous reports
              </div>
              <div class="sof-history-table-wrapper for-mobile-view-on-sof-and-reports">
                <ReportHistoryTable :rows-per-page="5" />
              </div>
            </div>

            <div
              v-show="isResultSeted && !dataSearchResult.length"
              key="spiner"
              class="flex full-height justify-center align-center"
            >
              <gl-loader progress-spinner />
            </div>
            <div
              v-show="isResultSeted && dataSearchResult.length"
              key="sourseResult"
            >
              <SourceResult
                :data-search-result="dataSearchResult"
                :is-result-seted="isResultSeted"
                @update-package-by-key="updateDataSearchResultByKey"
                @update-search-data="updateSearchData"
              />
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapState, mapActions, mapMutations } from 'vuex'
//Components
import SourceSearchBox from './components/SourceSearchBox.vue'
import SourceResult from '@/pages/source-of-funds/components/SourceResult.vue'
import GlLoader from '@/components/gl-loader'
import ReportHistoryTable from '@/pages/your-reports/components/ReportHistoryTable'

export default {
  name: 'SourceOfFunds',
  components: {
    SourceSearchBox,
    SourceResult,
    GlLoader,
    ReportHistoryTable,
  },
  data() {
    return {
      whatCheckModal: false,
      isResultSeted: false,
      dataSearchResult: [],
      activeValue: '',
    }
  },
  computed: {
    ...mapState('analytics', ['coinType']),
  },
  methods: {
    ...mapActions('report', ['purchaseReports']),
    ...mapActions('user', ['getMe']),
    ...mapMutations({
      SET_REDIRECT_PAYMENT_MODAL: 'payment/SET_REDIRECT_PAYMENT_MODAL',
    }),
    setDataSearchResult(data) {
      this.isResultSeted = Boolean(data.length)
      this.dataSearchResult = data
    },
    updateDataSearchResultByKey(e) {
      this.dataSearchResult = this.dataSearchResult.map(item => {
        if (e.reportPartName == item.reportPartName) {
          return { ...e }
        } else {
          return { ...item }
        }
      })
    },
    updateSearchData({ value, recalc }) {
      this.$refs.SourceSearchBoxRef.searchData(value, recalc)
    },
  },
}
</script>

<style>
:root {
  --sof-header-height: 190px;
}
.sof-wrapper {
  display: flex;
  justify-content: center;
  background-color: var(--light-grey-f-8);
  padding-top: var(--header-height);
  font-family: 'Montserrat', sans-serif;
}

.sof {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: var(--main-content-height);
}

.sof-head-wrapper {
  display: flex;
  justify-content: center;
}

.sof-head {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  padding-top: 24px;
  padding-bottom: 24px;
  height: var(--sof-header-height);
}

.sof-main-content-wrapper {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  background: var(--white);
  box-shadow: 0px 0px 100px 0px var(--dark-transparent);
  padding-top: 24px;
}
.sof-main-content-wrapper .sof-check-wrapper {
  padding-bottom: 96px;
}

.sof-main-content {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}

.sof-history-table-wrapper {
  height: calc(100% - 80px);
}

@media (max-width: 1023px) {
  .sof-head {
    height: auto;
    padding-bottom: 0;
  }
}
</style>
