<template>
  <div
    :class="[
      'gl-search-box',
      { 'gl-search-box__tagging': tagging },
      { 'gl-search-box__full': full },
      { 'gl-search-box__columns-auto': independent },
    ]"
  >
    <div class="gl-search-box__input-wrapper">
      <input
        v-model="innerValue"
        :class="[
          'gl-input__input',
          'gl-search-box__input',
          { 'gl-input__input--dark': dark },
          { 'gl-input__input--grey': grey },
          { 'gl-input__input--white': white },
          { 'gl-search-box__independent': independent },
        ]"
        :disabled="disabledField"
        :placeholder="placeholder"
        :style="[
          { height: height },
          { minWidth: minWidth },
        ]"
        type="text"
        @keyup.enter="searchValue"
      >
      <div class="flex">
        <transition name="fade">
          <gl-icon
            v-if="hasValue"
            class="gl-search-box__clear-icon"
            :class="[{ 'mr-4': independent }]"
            :height="24"
            :name="darkClear ? 'clear-large-dark' : 'clear-large'"
            :width="24"
            @click="clearValue"
          />
        </transition>
        <div
          v-if="independent"
          class="gl-search-box__independent-button"
          :class="{ 'gl-search-box__independent-button--disables': disabled }"
          @click="searchValue"
        >
          <gl-icon
            :height="24"
            name="find"
            :width="24"
          />
        </div>
      </div>
    </div>
    <Button
      v-if="!independent"
      class="gl-search-box-button"
      :disabled="disabled"
      :label="buttonText"
      :loading="loading"
      @click="searchValue"
    />
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
import GlButton from '@/components/gl-button'
import Button from 'primevue/button'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlIcon,
    GlButton,
    Button,
  },
  mixins: [deviceWidthMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    darkClear: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'search',
    },
    minWidth: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: '30px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledField: {
      type: Boolean,
      default: false,
    },
    tagging: {
      type: Boolean,
      default: false,
    },
    independent: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      innerValue: this.value,
    }
  },
  computed: {
    hasValue() {
      return Boolean(this.innerValue)
    },
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value)
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    },
  },
  methods: {
    clearValue() {
      this.innerValue = ''
      this.$emit('clear')
    },
    searchValue() {
      this.innerValue = this.innerValue.trim()
      this.$emit('search', this.innerValue)
    },
  },
}
</script>

<style>
.gl-search-box-button {
  min-width: 250px;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
}
</style>
