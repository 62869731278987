<template>
  <div>
    <div
      v-if="label"
      class="gl-input__label mb-2"
    >
      {{ label }}
    </div>
    <vSelect
      v-if="hasMultyCoin"
      v-model="coin"
      class="coin-select-base mr-2 t-mr-0"
      :class="[
        { 'coin-select-base__dark': dark },
        { 'coin-select-base__small': small },
      ]"
      :clearable="false"
      label="label"
      :options="currencyList"
      @input="handleCoinSelect"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapMutations, mapState } from 'vuex'
import config from '@/utils/appConfig'

export default {
  components: {
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    iconHeight: {
      type: Number,
      default: 16,
    },
    iconWidth: {
      type: Number,
      default: 16,
    },
    iconAfter: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      coin: {},
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'currencyList']),
    hasMultyCoin() {
      return config.VUE_APP_COIN_TYPE
    },
  },
  watch: {
    coinType: {
      handler(coin) {
        this.changeCoin(coin)
      },
      immediate: true,
    },
    currencyList: {
      handler(coin) {
        this.changeCoin(coin)
      },
    }
  },
  methods: {
    ...mapMutations({
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
    }),
    handleCoinSelect(data) {
      this.SET_COIN_TYPE(data.key)
      this.SET_COIN_DATA(data)
      this.$emit('change', data)
    },
    changeCoin(coin) {
      this.coin =
        this.currencyList.find(item => item.key === coin) ||
        this.currencyList[0]
    }
  },
}
</script>

<style>
.coin-select-base.p-dropdown {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-color: var(--reflex-bluet);
  border-radius: 4px;
}
.coin-select-base.p-dropdown .p-dropdown-label {
  color: var(--dark);
}

.coin-select-base .vs__dropdown-toggle {
  min-height: 50px;
  width: 170px;
  box-shadow: 0px 0px 15px 0px var(--dark-transparent);
}
.coin-select-base .vs__selected-options {
  flex-wrap: nowrap;
}
.coin-select-base .vs__selected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coin-select-base__dark .vs__dropdown-toggle {
  border: 1px solid #f5f5f5 !important;
  min-height: 50px;
  min-width: 130px;
}

.coin-select-base__small .vs__dropdown-toggle {
  min-height: 30px;
}

.vs--single.vs--open .vs__selected {
  position: relative;
}
</style>
