<template>
  <div class="sof-box relative">
    <div class="sof-box-preview">
      <component :is="sectionsComponent" />
    </div>

    <div
      class="sof-box-main space-between gap-30 flex-items-center flex-1 relative"
      :class="{
        'sof-box-hover': showHoverOnCard,
        'sof-box-hover-full-details': hoverAllPackage,
      }"
    >
      <div class="max-w-280">
        <div
          class="sof-box-cover"
          :class="{
            'sof-box-hover': showHoverOnCard,
            'sof-box-hover-full-details': hoverAllPackage,
          }"
        />
        <div class="sof-box-head-text mb-2 t-mb-3">
          {{ cartData.title }}
        </div>
        <div class="sof-box-text t-mb-3">
          {{ cartData.description }}
        </div>
      </div>
      <div class="flex column justify-center">
        <transition-group name="fade-leave">
          <div
            v-if="cartData.fakeLoadingPreview >= 0"
            :key="order + 'loader'"
          >
            <gl-loader
              spin-dot
              width="70px"
            />
          </div>
          <div
            v-else
            :key="order + 'tag'"
          >
            <GlInfoTag
              class="bold-600 max-w-200"
              :type="typeOfTag"
            >
              <gl-icon
                class="mr-2"
                :failure="typeOfTag === 'failure'"
                :height="20"
                :name="typeOfTag === 'failure' ? 'failure' : 'done'"
                :some="typeOfTag === 'some'"
                :success="typeOfTag === 'success'"
                :warning="typeOfTag === 'warning'"
                :width="20"
              />
              <div class="text-center">
                {{ cartData.status }}
              </div>
            </GlInfoTag>
          </div>
        </transition-group>
      </div>
      <div>
        <div
          v-if="cartData.reportPartName === 'owner'"
          class="bold text-center min-w-250"
        >
          Included in next reports
        </div>
        <GlButtonPrime
          v-else
          class="sof-box-button min-w-250 height-50 bold-600 fs-16"
          :class="{
            'sof-box-button-hover p-button-success': showHoverOnButton,
            'p-button-warning': cartData.reportPartName == 'full',
          }"
          :disabled="typeOfTag === 'failure'"
          :loading="cartData.loading"
          @click="$emit('button-click', cartData)"
          @mouseleave="onMouseleaverButton(order)"
          @mouseover="onMouseoverButton(order)"
        >
          <transition-group name="slide">
            <div
              v-if="showHoverOnButton"
              :key="order + 'Included'"
              class="flex justify-center"
            >
              <gl-icon
                class="mr-2"
                :height="20"
                name="done"
                white
                :width="20"
              />
              Included
            </div>
            <div
              v-else
              :key="order + 'thunder'"
              class="flex justify-center"
            >
              {{
                cartData.purchased
                  ? 'View'
                  : `Unlock for ${cartData.price || ''}`
              }}

              <gl-icon
                v-if="!cartData.purchased"
                class="ml-1 mr-2"
                :height="20"
                name="lookups-white"
                :width="20"
              />
            </div>
          </transition-group>
        </GlButtonPrime>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import GlButtonPrime from '@/components/gl-button-prime'
import GlIcon from '@/components/gl-icon'
import GlLoader from '@/components/gl-loader'
import GlInfoTag from '@/components/gl-info-tag'
// Utils
import { ReportPackagesStatusMap } from '@/utils/get-status'
import { getObjectValueByKey } from '@/utils/helpers'

const Owner = () =>
  import('@/pages/source-of-funds/components/card-components/Owner.vue')
const Score = () =>
  import('@/pages/source-of-funds/components/card-components/Score.vue')
const Types = () =>
  import('@/pages/source-of-funds/components/card-components/Types.vue')
const Entities = () =>
  import('@/pages/source-of-funds/components/card-components/Entities.vue')
const FullDetails = () =>
  import('@/pages/source-of-funds/components/card-components/FullDetails.vue')

export default {
  components: {
    GlIcon,
    GlLoader,
    GlButtonPrime,
    GlInfoTag,
  },
  props: {
    order: {
      type: Number,
      default: null,
    },
    hoveredPackageInd: {
      type: Number,
      default: null,
    },
    hoverAllPackage: {
      type: Boolean,
      default: false,
    },
    cartData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loadingTimer: null,
      ReportPackagesStatusMap,
    }
  },
  computed: {
    sectionsComponent() {
      if (this.cartData.reportPartName == 'owner') return Owner
      if (this.cartData.reportPartName == 'score') return Score
      if (this.cartData.reportPartName == 'type') return Types
      if (this.cartData.reportPartName == 'entity') return Entities
      if (this.cartData.reportPartName == 'full') return FullDetails
      return FullDetails
    },
    showHoverOnCard() {
      if (
        (this.hoveredPackageInd && this.hoveredPackageInd >= this.order) ||
        this.hoveredPackageInd == this.order
      ) {
        return true
      } else {
        return false
      }
    },
    showHoverOnButton() {
      if (this.hoveredPackageInd && this.hoveredPackageInd > this.order) {
        return true
      } else {
        return false
      }
    },
    typeOfTag() {
      return (
        this.getObjectValueByKey(ReportPackagesStatusMap, this.cartData.status) ||
        'success'
      )
    },
  },
  watch: {
    'cartData.fakeLoadingPreview': {
      handler() {
        this.startLoadingTimer()
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    clearTimeout(this.loadingTimer)
  },
  methods: {
    getObjectValueByKey,
    onMouseoverButton(ind) {
      this.$emit('update-hovered-package-ind', ind)
      if (this.cartData.reportPartName == 'full') {
        this.$emit('update-hover-all-package', true)
      }
    },
    onMouseleaverButton() {
      this.$emit('update-hovered-package-ind', null)
      this.$emit('update-hover-all-package', false)
    },
    startLoadingTimer(interval = 1000) {
      this.loadingTimer = setTimeout(() => {
        if (this.cartData.fakeLoadingPreview >= 0) {
          this.$emit('update-package-by-key', {
            ...this.cartData,
            fakeLoadingPreview: this.cartData.fakeLoadingPreview - 1,
          })
        }
      }, interval)
    },
  },
  beforeRouteLeave(from, to, next) {
    clearTimeout(this.loadingTimer)
    next()
  },
}
</script>

<style>
.sof-box {
  margin-bottom: 12px;
}

.sof-box-cover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 8px;
  -webkit-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all.5s linear;
}

.sof-box-hover.sof-box-main {
  border-color: var(--green) !important;
}

.sof-box-hover.sof-box-cover {
  background: #70ac3f1a;
  -webkit-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.sof-box-hover-full-details.sof-box-main {
  border-color: var(--warning) !important;
}

.sof-box-hover-full-details.sof-box-cover {
  background: #fba1191a;
  -webkit-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.sof-box-button {
  position: relative;
  z-index: 3;
  transition: all 0.5s linear;
}

.sof-box-button-hover.sof-box-button {
  border-radius: 37px;
  transition: all 0.5s linear;
}

.sof-box {
  flex: 1;
  display: flex;
  gap: 12px;
  font-family: 'Montserrat';
}
.sof-box .sof-box-preview,
.sof-box .sof-box-main {
  display: flex;
  border: 1px solid;
  border-color: var(--pale-grey);
  padding: 16px;
  border-radius: 8px;
  -webkit-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.sof-box .sof-box-preview {
  width: 200px;
  padding: 8px;
}

.sof-box-head-text {
  color: var(--dark);
  font-weight: 600;
  font-size: 16px;
}
.sof-box-text {
  color: var(--space-cadet);
  font-size: 12px;
  line-height: 18px;
}

@media (max-width: 1023px) {
  .sof-box {
    display: block;
    border: 1px solid var(--pale-grey);
    padding: 16px;
    border-radius: 8px;
  }
  .sof-box:last-child {
    margin-bottom: 0;
  }
  .sof-box .sof-box-main {
    display: block;
    padding: 0;
    border: none;
  }
  .sof-box .sof-box-main .max-w-280 {
    max-width: 100%;
  }
  .sof-box .sof-box-main .info-tag {
    max-width: 100% !important;
    margin: 0 auto 24px auto;
  }
  .sof-box-button {
    width: 100%;
  }
  .sof-box .sof-box-preview {
    justify-content: center;
    width: 100%;
    margin: 0 auto 24px auto;
  }
}
@media (max-width: 767px) {
}
</style>
